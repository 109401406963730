import { atom, selector } from 'recoil';
import { v4 } from 'uuid';
import { ISurvey } from '../../interfaces/contract/survey.model';

export interface IContractSyncSurvey {
    readyForDisplay: boolean;
    finishedSyncing: boolean;
    photos: {
        ready: string[];
        fetching: string[];
    };
}

export interface IContractSurveyState {
    [key: string]: IContractSyncSurvey;
}

export const contractSurveyStateRecoilState = atom<IContractSurveyState>({
    key: `contract-survey-state/${v4()}`,
    default: {},
});

export const photoIsReady = (surveyId: string, slotName: string) => {
    return selector<boolean>({
        key: `contract-survey-state-photo-ready-${surveyId}-${slotName}/${v4()}`, // unique ID (with respect to other atoms/selectors)
        get: ({ get }) => {
            return (
                get(contractSurveyStateRecoilState)[surveyId]?.photos.ready.includes(slotName) ??
                false
            );
        },
    });
};

export const photoIsFetching = (surveyId: string, slotName: string) => {
    return selector<boolean>({
        key: `contract-survey-state-photo-fetching-${surveyId}-${slotName}/${v4()}`, // unique ID (with respect to other atoms/selectors)
        get: ({ get }) => {
            return (
                get(contractSurveyStateRecoilState)[surveyId]?.photos.fetching.includes(slotName) ??
                false
            );
        },
    });
};

export const surveyIsReadyForDisplay = (surveyId: string) => {
    return selector<boolean>({
        key: `contract-survey-state-survey-ready-${surveyId}/${v4()}`, // unique ID (with respect to other atoms/selectors)
        get: ({ get }) => {
            return get(contractSurveyStateRecoilState)[surveyId]?.readyForDisplay ?? false;
        },
    });
};

export const finishedSyncing = (check: boolean, surveys: ISurvey[]) => {
    return selector<boolean>({
        key: `contract-survey-state-finished-syncing/${v4()}`, // unique ID (with respect to other atoms/selectors)
        get: ({ get }) => {
            if (!check) {
                return true;
            }
            const syncSurveys = Object.entries(get(contractSurveyStateRecoilState)).filter(
                ([surveyId]) => surveys.some((survey) => survey.id === surveyId)
            );
            if (surveys.length === syncSurveys.length) {
                return !syncSurveys.some(([, syncSurvey]) => !syncSurvey.finishedSyncing);
            }
            return false;
        },
    });
};
