import { useQuery } from 'react-query';
import { useApi } from '../api/useApi';
import { contractKeys, singleContractQueryReturn } from './queryKeyFactory';
import { IContract } from '../../interfaces/contract/contract.model';
import { gql } from 'graphql-request';

export const useContract = (contractId?: string) => {
    const { postGql } = useApi();

    return useQuery(
        contractKeys.single(contractId ?? ''),
        async () => {
            const { contract } = await postGql<{ contract: IContract }>(
                gql`
                    query ($id: ID!) {
                        contract(id: $id) {
                            ${singleContractQueryReturn}
                        }
                    }
                `,
                { id: contractId },
                'queryContract'
            );
            return contract;
        },
        {
            enabled: contractId !== undefined,
            staleTime: 0,
        }
    );
};
