import { useState } from 'react';
import { useContractsToUpload } from './hooks/useContractsToUpload';
import { ContractsToUploadModal } from './ContractsToUploadModal';

export const ContractsToUploadBar = () => {
    const contractsToUpload = useContractsToUpload();

    const [showModal, setShowModal] = useState(false);

    const handleModalOpen = () => {
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    return (
        <>
            {contractsToUpload.length > 0 && (
                <div
                    className="cursor-pointer bg-red-500 py-2 px-4 font-bold text-white hover:bg-red-400"
                    onClick={handleModalOpen}
                >
                    Es gibt überfällige Aufträge. Bitte laden sie diese hoch.
                </div>
            )}

            <ContractsToUploadModal
                contracts={contractsToUpload}
                show={showModal}
                onClose={handleModalClose}
            />
        </>
    );
};
