import { useCallback, useEffect, useState } from 'react';
import { useLoadVault } from '../components/BLForms/hooks/useLoadVault';
import { IContract } from '../interfaces/contract/contract.model';
import { IPhotoVault } from '../../../shared/interfaces/backend/protocol.interface';
import { usePhotoVault } from '../components/photos/hooks/usePhotoVault';
import { useLatest } from 'react-use';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { contractUploadState } from '../recoil/atoms/contractUploadState';
import { finishedSyncing } from '../recoil/atoms/contractSurveyState';

interface ISummaryProgress {
    contract?: IContract;
    autoStart?: boolean;
}

const useSummaryProgress = (props: ISummaryProgress) => {
    const { contract, autoStart } = props;

    const setContractUploadState = useSetRecoilState(contractUploadState);

    const ready = useRecoilValue(finishedSyncing(autoStart ?? false, contract?.surveys ?? []));

    const { loadVault } = useLoadVault();
    const { loadVault: loadPhotoVault } = usePhotoVault();

    const [isDone, setIsDone] = useState<boolean | null>(null);

    const isDoneLatest = useLatest(isDone);

    const calcPhotoProgress = useCallback((vault: IPhotoVault) => {
        if (vault && vault.photos) {
            let goal = 0;
            let current = 0;

            vault?.photos.forEach((photo) => {
                if (photo.required) goal++;
                if (photo.required && photo.filePath !== '' && !photo.denied) current++;
                if (photo.required && photo.denied) current++;
            });

            return Math.abs((current / goal) * 100);
        }
    }, []);

    /**
     * Does calculate the forms and photos together.
     * Each 100% Vault equals 1, each used survey adds one to total
     */
    const calc = useCallback(
        async (force = false) => {
            let total = 0;
            let progressTemp = 0;

            if (contract?.surveys && (!isDoneLatest.current || force)) {
                // ### Forms
                for (const survey of contract.surveys) {
                    // Skip deleted
                    if (survey.deleted !== null) continue;

                    total++;

                    const vaultFile = await loadVault({
                        contractId: contract.id,
                        survey,
                    });

                    if (vaultFile && vaultFile.meta.progress) {
                        if (vaultFile.meta.progress >= 100) {
                            progressTemp++;
                        }
                    }

                    // console.log('VF', vaultFile, progressTemp, total);
                }

                // ### Photo Vaults
                for (const survey of contract.surveys) {
                    // Skip deleted
                    if (survey.deleted !== null) continue;

                    total++;

                    const photoVaultFile = await loadPhotoVault({
                        contractId: contract.id,
                        survey,
                    });

                    if (photoVaultFile) {
                        const pProgress = calcPhotoProgress(photoVaultFile);
                        if (pProgress && pProgress >= 100) {
                            progressTemp++;
                        }
                    }

                    // console.log('PVF', photoVaultFile, progress, total);
                }

                const totalSum = Math.abs((progressTemp / total) * 100);
                setContractUploadState((currVal) => {
                    return { ...currVal, [contract.id]: totalSum };
                });
            }
        },
        [
            calcPhotoProgress,
            contract?.id,
            contract?.surveys,
            isDoneLatest,
            loadPhotoVault,
            loadVault,
            setContractUploadState,
        ]
    );

    useEffect(() => {
        if (ready) {
            setIsDone(false);
            void calc();
        }
    }, [calc, ready]);

    return { calc };
};

export default useSummaryProgress;
