import React from 'react';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

interface IOverdueDate {
    date: string;
    isOverdue: boolean;
}

export const OverdueDate: React.FC<IOverdueDate> = (props) => {
    const { date, isOverdue } = props;
    return (
        <>
            {isOverdue && (
                <span className={`flex flex-row items-center gap-1 text-red-700`}>
                    <span data-test-id="contractDeadline">
                        {dayjs(date).format('DD.MM.YYYY HH:mm')}
                    </span>
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="animate-pulse text-red-600"
                    />
                </span>
            )}
            {!isOverdue && (
                <span
                    className={`flex flex-row items-center gap-1 text-neutral-500`}
                    data-test-id="contractDeadline"
                >
                    {dayjs(date).format('DD.MM.YYYY HH:mm')}
                </span>
            )}
        </>
    );
};
