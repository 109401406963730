import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';
import ContractCard from './ContractCard';
import { IContractsWithData } from './ContractList';
import { IContract } from '../../interfaces/contract/contract.model';
import { Chip } from '../atoms/Chips/Chip';
import { useRecoilValue } from 'recoil';
import { frontendContractList } from './recoil/contractList';
import ContractCardCondensed from './ContractCardCondensed';

interface IContractListColumn {
    contractsFiltered: IContractsWithData;
    stepName: string;
    userId: number;
    openedContracts: string[];
}

export const ContractListColumn: React.FC<IContractListColumn> = (props) => {
    const { contractsFiltered, stepName, userId, openedContracts } = props;

    const contractList = useRecoilValue(frontendContractList);

    const hasPinned = useMemo(() => {
        const pinned = contractsFiltered.pinned[stepName] as string[];
        return !!pinned.length;
    }, [contractsFiltered.pinned, stepName]);

    const hasUnPinned = useMemo(() => {
        const unPinned = contractsFiltered.unPinned[stepName] as string[];
        return !!unPinned.length;
    }, [contractsFiltered.unPinned, stepName]);

    const contractsPinned = useMemo(() => {
        const contractsInStep = contractsFiltered.lists[stepName] as IContract[];
        return contractsInStep.filter((contract) => {
            const pinnedInStep = contractsFiltered.pinned[stepName] as string[];
            return pinnedInStep.includes(contract.id);
        });
    }, [contractsFiltered.lists, contractsFiltered.pinned, stepName]);

    const contractsUnPinned = useMemo(() => {
        const contractsInStep = contractsFiltered.lists[stepName] as IContract[];
        return contractsInStep.filter((contract) => {
            const unPinnedInStep = contractsFiltered.unPinned[stepName] as string[];
            return unPinnedInStep.includes(contract.id);
        });
    }, [contractsFiltered.lists, contractsFiltered.unPinned, stepName]);

    return (
        <div
            className="relative flex w-full grid-cols-2 flex-col sm:grid lg:flex"
            data-test-id={`contractColumn-${stepName}`}
        >
            {/* List of Pinned Contracts */}
            {hasPinned && (
                <div className="mb-12 flex w-full flex-col">
                    <div className="relative mb-3 flex h-10 w-full flex-row items-center justify-center">
                        <div className="flex w-full items-center justify-center border-b-[1px] border-neutral-300">
                            <span className="relative top-[14px] bg-neutral-200 px-2 py-2 text-xs text-primary">
                                Angeheftete Aufträge
                                <FontAwesomeIcon
                                    icon={faThumbtack}
                                    className="rotate-12 px-2 text-neutral-400"
                                />
                            </span>
                        </div>
                    </div>

                    {contractsPinned.map((contract: IContract) => {
                        return (
                            <div
                                key={'contract-card-' + contract.id}
                                className="flex w-full flex-col gap-0"
                            >
                                <>
                                    {contractList.listView === 'default' && (
                                        <ContractCard
                                            contract={contract}
                                            opened={openedContracts.includes(contract.id)}
                                            userId={userId}
                                            displayShowOnMap={true}
                                        />
                                    )}
                                    {contractList.listView === 'condensed' && (
                                        <ContractCardCondensed
                                            contract={contract}
                                            opened={openedContracts.includes(contract.id)}
                                            userId={userId}
                                        />
                                    )}
                                </>
                            </div>
                        );
                    })}
                </div>
            )}

            {/* List of UnPinned Contracts */}
            {hasUnPinned && (
                <div className="mb-12 flex w-full flex-col">
                    <div className="relative mb-3 flex h-10 w-full items-center justify-center">
                        <div className="flex w-full items-center justify-center border-b-[1px] border-neutral-300">
                            <span className="relative top-[14px] bg-neutral-200 px-2 py-2 text-xs text-primary">
                                Aufträge
                            </span>
                        </div>
                    </div>

                    {contractsUnPinned.map((contract: IContract) => {
                        return (
                            <div
                                key={'contract-card-' + contract.id}
                                className="flex w-full flex-col gap-0"
                            >
                                <>
                                    {contractList.listView === 'default' && (
                                        <ContractCard
                                            contract={contract}
                                            opened={openedContracts.includes(contract.id)}
                                            userId={userId}
                                            displayShowOnMap={true}
                                        />
                                    )}
                                    {contractList.listView === 'condensed' && (
                                        <ContractCardCondensed
                                            contract={contract}
                                            opened={openedContracts.includes(contract.id)}
                                            userId={userId}
                                        />
                                    )}
                                </>
                            </div>
                        );
                    })}
                </div>
            )}

            {!hasPinned && !hasUnPinned && (
                <div className="flex w-full items-center justify-center p-4 pt-12">
                    <Chip>Keine Aufträge in dieser Liste</Chip>
                </div>
            )}
        </div>
    );
};
