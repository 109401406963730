import { atom, selector } from 'recoil';
import { v4 } from 'uuid';
import { nanoid } from 'nanoid';

export type TListView = 'default' | 'condensed';

export type TQueryType = 'address' | 'fulltext';

export interface IFrontendContractList {
    query: string;
    addressQuery: string | null;
    queryType: TQueryType;
    filterAussen: boolean;
    filterInnen: boolean;
    filterPinned: boolean;
    filterToday: boolean;
    listView: TListView;
    showFilterModal: boolean;
    showOnlyOpenDates: boolean;
    showMap: boolean;
}

export const frontendContractList = atom<IFrontendContractList>({
    key: `frontend-contract-list-state/${v4()}`,
    default: {
        query: '',
        addressQuery: null,
        queryType: 'fulltext',
        filterInnen: true,
        filterAussen: true,
        filterPinned: false,
        filterToday: false,
        listView: 'default',
        showFilterModal: false,
        showOnlyOpenDates: false,
        showMap: false,
    },
});

export const getShowMap = selector<boolean>({
    key: `get-show-map/${nanoid()}`,
    get: ({ get }): boolean => {
        return get(frontendContractList).showMap;
    },
});

export const getShowOnlyOpenDates = selector<boolean>({
    key: `get-show-only-open-dates/${nanoid()}`,
    get: ({ get }): boolean => {
        return get(frontendContractList).showOnlyOpenDates;
    },
});

export const getListView = selector<TListView>({
    key: `get-list-view/${nanoid()}`,
    get: ({ get }): TListView => {
        return get(frontendContractList).listView;
    },
});

export const getQueryType = selector<TQueryType>({
    key: `get-query-type/${nanoid()}`,
    get: ({ get }): TQueryType => {
        return get(frontendContractList).queryType;
    },
});

export const getFilterPinned = selector<boolean>({
    key: `get-filter-pinned/${nanoid()}`,
    get: ({ get }): boolean => {
        return get(frontendContractList).filterPinned;
    },
});

export const getFilterToday = selector<boolean>({
    key: `get-filter-today/${nanoid()}`,
    get: ({ get }): boolean => {
        return get(frontendContractList).filterToday;
    },
});
