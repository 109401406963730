import { useQuery } from 'react-query';
import { gql } from 'graphql-request';
import { useApi } from '../api/useApi';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../components/Auth/userAtom';
import { IRole } from 'src/interfaces/user/role.model';

export const useUserRoles = () => {
    const { postGql } = useApi();

    const { id: userId } = useRecoilValue(userAtom);

    return useQuery(
        ['userRoles'],
        async () => {
            const { user } = await postGql<{ user: { roles: IRole[] } }>(
                gql`
                    query ($id: Int!) {
                        user(id: $id) {
                            roles {
                                admin
                                access
                                name
                            }
                        }
                    }
                `,
                { id: Number(userId) },
                `userRoles${userId}`
            );
            return user.roles;
        },
        {
            enabled: !!userId,
        }
    );
};

