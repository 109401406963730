import React from 'react';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IButtonRound {
    icon: IconDefinition;
    onClick: () => void;
    clean?: boolean;
    animate?: boolean;
    testId?: string;
}

export const ButtonRound: React.FC<IButtonRound> = (props) => {
    const { icon, onClick, clean, animate, testId } = props;
    return (
        <>
            <button
                data-test-id={testId}
                className={`relative flex h-8 w-8 items-center justify-center rounded-full ${
                    animate ? 'animate-spin' : ''
                } ${
                    clean ? 'bg-transparent' : 'bg-neutral-500'
                } p-2 opacity-100 transition hover:bg-amber-100`}
                onClick={() => onClick()}
            >
                <FontAwesomeIcon
                    icon={icon}
                    className={`${clean ? 'text-neutral-500' : 'text-white'}`}
                />
            </button>
        </>
    );
};

ButtonRound.defaultProps = {
    clean: false,
    animate: false,
};
