import { IUser } from '../../interfaces/user/user.model';
import { useQueryClient } from 'react-query';
import { useCallback } from 'react';

export const useContractIsPopulated = () => {
    const queryClient = useQueryClient();

    const isPopulated = useCallback(
        (contractId: string) => {
            const cachedData: IUser | false | undefined = queryClient.getQueryData<IUser | false>([
                'contract',
                contractId,
            ]);

            return !!cachedData;
        },
        [queryClient]
    );

    return {
        isPopulated,
    };
};
