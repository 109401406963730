import React, { useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { frontendContractList } from './recoil/contractList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/pro-duotone-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

interface IQueryInfo {}

export const QueryInfo: React.FC<IQueryInfo> = () => {
    const contractListState = useRecoilValue(frontendContractList);
    const setContractListState = useSetRecoilState(frontendContractList);

    const queryMessage = useMemo(() => {
        const message: string[] = [];

        // Query
        if (contractListState.query !== '')
            message.push(`Stichwort: <strong>${contractListState.query}</strong>`);

        // Adressauswahl
        if (contractListState.addressQuery) message.push(`mit <strong>Adresseauswahl</strong>`);

        // Termine
        if (contractListState.showOnlyOpenDates)
            message.push(`<strong>Aufgabe für Termin machen</strong>`);

        // Nur Pinned
        if (contractListState.filterPinned) message.push(`<strong>gepinnte Aufträge</strong>`);

        // Only Today
        if (contractListState.filterToday)
            message.push(`<strong>Besichtigungstermin heute</strong>`);

        // Innen / Aussen
        if (!contractListState.filterInnen || !contractListState.filterAussen) {
            if (contractListState.filterAussen)
                message.push('<strong>nur Aussenbesichtigungen</strong>');
            if (contractListState.filterInnen)
                message.push('<strong>nur Innenbesichtigungen</strong>');
        }

        return message.join(' und ');
    }, [contractListState]);

    const finalMessage = useMemo(() => {
        if (queryMessage === '') return '';

        return `Mit ${queryMessage}`;
    }, [queryMessage]);

    const resetFilter = useCallback(() => {
        setContractListState((currVal) => {
            return {
                ...currVal,
                addressQuery: null,
                query: '',
                queryType: 'fulltext',
                filterAussen: true,
                filterInnen: true,
                showOnlyOpenDates: false,
                filterPinned: false,
                filterToday: false,
            };
        });
    }, [setContractListState]);

    return (
        <>
            {finalMessage && (
                <div className="queryInfo font-serif text-sm text-neutral-600">
                    <FontAwesomeIcon icon={faFilter} className="mr-1 h-[14px] text-neutral-400" />
                    <span dangerouslySetInnerHTML={{ __html: finalMessage ?? '' }}></span>
                    <button>
                        <FontAwesomeIcon
                            icon={faXmark}
                            className="h-3 px-3 text-neutral-600"
                            onClick={() => resetFilter()}
                        />
                    </button>
                </div>
            )}
        </>
    );
};
