import { useEffect, useState } from 'react';

export const useLocalhost = () => {
    const [isLocalhost, setIsLocalhost] = useState<null | boolean>(null);

    useEffect(() => {
        if (window && window.location.host) {
            setIsLocalhost(window.location.host.includes('localhost'));
        }
    }, []);

    return isLocalhost;
};
