import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { EContractState, IContract } from 'src/interfaces/contract/contract.model';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import { useNetworkState } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faThumbtack } from '@fortawesome/free-solid-svg-icons';
import { StarIcon } from '@heroicons/react/solid';
import { useContractFavourContract } from '../../hooks/contracts/useContractFavourContract';
import { useContractUnFavourContract } from '../../hooks/contracts/useContractUnFavourContract';
import { Chip } from '../atoms/Chips/Chip';
import { useContractTypeIcon } from './hooks/useContractTypeIcon';
import {
    EContractType,
    EObjectType,
} from '../../../../shared/interfaces/backend/contract.interface';
import { useContractHelper } from './hooks/useContractHelper';
import { OverdueDate } from '../atoms/OverdueDate';
import { useLocalhost } from '../../hooks/useLocalhost';
import { useContractTypeLabel } from '../../hooks/useContractTypeLabel';
import ContractCardProgress from './ContractCardProgress';
import { useContractIsPopulated } from '../../hooks/contracts/useContractIsPopulated';
import Ripples from 'react-ripples';
import { ContractMapPushButton } from './ContractMapPushButton';

interface IContractCard {
    contract: IContract;
    opened: boolean;
    userId: number | undefined;
    displayShowOnMap?: boolean;
}

const ContractCard: React.FC<IContractCard> = (props) => {
    const { contract, opened, userId, displayShowOnMap } = props;

    const cardRef = useRef<HTMLDivElement | null>(null);

    const { push } = useRouter();
    const { online } = useNetworkState();

    const contractTypeLabel = useContractTypeLabel(contract.contractType);

    const { mutate: pinContract } = useContractFavourContract();
    const { mutate: unPinContract } = useContractUnFavourContract();

    const { getIcon } = useContractTypeIcon();

    const { amountWEs, isOverdue } = useContractHelper(contract);

    const isLocalhost = useLocalhost();

    const { isPopulated } = useContractIsPopulated();

    const openContract = useCallback(async () => {
        if (online) {
            await push(`/contract/?id=${contract.id}`);
        } else {
            if (isPopulated(contract.id)) {
                await push(`/contract/?id=${contract.id}`);
            } else {
                toast.info('Der Auftrag ist noch nicht in den Offline speicher geladen worden!');
            }
        }
    }, [contract.id, isPopulated, online, push]);

    const isPinned = useMemo(() => {
        if (!userId) {
            return false;
        }

        return contract.favoured?.includes(String(userId));
    }, [contract.favoured, userId]);

    /**
     * Scroll to the last opened contract
     */
    useEffect(() => {
        const lastContractId = sessionStorage.getItem('lastContractId');

        if (lastContractId === contract.id && cardRef.current) {
            const topPos = cardRef.current.getBoundingClientRect().top;
            window.scrollTo({
                behavior: 'smooth',
                top: topPos + window.scrollY - 124, // Header height
            });

            // Remove to prevent scrolling to the same contract
            sessionStorage.removeItem('lastContractId');
        }
    }, [contract.id]);

    return (
        <>
            {online !== undefined && (
                <div
                    className={`relative flex w-full flex-row border-b border-neutral-300 bg-white text-left ${
                        isOverdue ? 'bg-red-50' : 'bg-white'
                    }`}
                    ref={cardRef}
                    data-test-type={'contractCard'}
                    data-test-id={`contractCard-${contract.fileNumber}`}
                    key={`contractCardButtonWrapper-${contract.fileNumber}`}
                >
                    {/* Balken */}
                    <div className="relative flex h-full w-12 flex-col items-center justify-center gap-2 bg-primary">
                        <FontAwesomeIcon
                            icon={getIcon(contract.contractType as EContractType | undefined)}
                            className="text-xl text-white"
                        />

                        {amountWEs > 0 && (
                            <div className="relative top-1 flex w-full flex-row items-center justify-center gap-2">
                                <span className="text-base text-white">{amountWEs ?? 0}</span>
                            </div>
                        )}
                    </div>

                    <div className="flex w-full flex-col gap-0">
                        <div
                            className="hidden text-xs"
                            data-test-id={`contractCard-${contract.fileNumber}`}
                        >
                            {contract.fileNumber}
                        </div>

                        {contract.inCorrection && contract.state === 'Surveying' && (
                            <>
                                <div className="relative flex w-full items-center justify-center gap-1 bg-red-600 p-1 text-xs text-white">
                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                    <span>Korrektur!</span>
                                </div>
                            </>
                        )}

                        {/* Header - Title + Buttons */}
                        <div className="flex w-full flex-col p-2">
                            <Ripples
                                color={'rgba(0, 68, 139, 0.5)'}
                                during={500}
                                className={`relative h-max overflow-hidden rounded`}
                            >
                                <button
                                    className="group relative flex flex-col"
                                    onClick={() =>
                                        setTimeout(() => {
                                            void openContract();
                                        }, 500)
                                    }
                                    data-test-id={`contractCardButton-${contract.fileNumber}`}
                                    key={`contractCardButton-${contract.fileNumber}`}
                                >
                                    <div className="relative flex flex-col justify-start text-left">
                                        <span className="font-serif tracking-wide text-primary group-hover:underline">
                                            {contract.address?.postalCode} {contract.address?.city}{' '}
                                            - IM-{String(contract.id).padStart(7, '0')}
                                        </span>
                                        <span className="text-sm tracking-wider text-primary group-hover:underline">
                                            {contractTypeLabel}
                                        </span>
                                        <span className="text-sm tracking-wider text-primary group-hover:underline">
                                            {contract.fileNumber}
                                        </span>
                                    </div>
                                </button>
                            </Ripples>

                            <section className="absolute right-2 top-2 flex w-max flex-row justify-end gap-2">
                                {/* New Indicator */}
                                {!opened && <StarIcon className="2 relative w-6 text-primary/50" />}

                                {/* Not pinned */}
                                {!isPinned && (
                                    <button
                                        className="group flex h-8 w-8 items-center justify-center rounded-full p-2 transition-all hover:bg-primary-light/30"
                                        onClick={() => {
                                            pinContract(contract.id);
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faThumbtack}
                                            className="rotate-12 text-primary/50 transition-all hover:rotate-0 hover:text-primary"
                                        />
                                    </button>
                                )}

                                {/* Pinned */}
                                {isPinned && (
                                    <button
                                        className="flex h-8 w-8 items-center justify-center rounded-full bg-primary p-2"
                                        onClick={() => {
                                            unPinContract(contract.id);
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faThumbtack}
                                            className="text-white"
                                        />
                                    </button>
                                )}
                            </section>
                        </div>

                        <button
                            className="group relative flex flex-col text-left"
                            onClick={() => void openContract()}
                        >
                            <div className="flex w-full flex-col p-2">
                                <span className="text-sm text-neutral-500">
                                    <span className="uppercase">{contract.address?.country}</span>{' '}
                                    {contract.address?.street} {contract.address?.houseNo}
                                    {contract.company?.nameShort
                                        ? ` - ${contract.company?.nameShort} ${
                                              isLocalhost ? contract.formType : ''
                                          }`
                                        : ''}
                                </span>

                                <span className="text-sm text-neutral-500">
                                    {contract.objectType && EObjectType[contract.objectType]}
                                    <>
                                        {location.host.includes('localhost') && (
                                            <> -{contract.formType}</>
                                        )}
                                    </>
                                </span>
                            </div>
                            <div className="relative grid w-full grid-cols-[3fr,9fr] p-2 text-sm text-neutral-500">
                                <span className="text-primary">Termin</span>
                                <span>
                                    {contract.surveyDate ? (
                                        dayjs(contract.surveyDate).format('DD.MM.YYYY HH:mm')
                                    ) : (
                                        <>
                                            {contract.state === EContractState.Surveying && (
                                                <Chip color="danger">TERMIN MACHEN</Chip>
                                            )}
                                        </>
                                    )}
                                </span>
                                <span className="text-neutral-500">Abgabe</span>
                                {contract.deadlineSV && (
                                    <OverdueDate date={contract.deadlineSV} isOverdue={isOverdue} />
                                )}
                                <span className="text-red-800"></span>
                                <span>
                                    {contract.express && <Chip color="severe">EXPRESS</Chip>}
                                </span>
                            </div>
                            {contract.state === EContractState.Surveying && (
                                <ContractCardProgress contract={contract} />
                            )}
                        </button>

                        {displayShowOnMap && (
                            <div
                                className={`absolute right-2 z-30 ${
                                    contract.state === EContractState.Surveying
                                        ? 'bottom-10'
                                        : 'bottom-1'
                                }`}
                            >
                                <ContractMapPushButton contract={contract} />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

ContractCard.defaultProps = {
    opened: false,
    displayShowOnMap: false,
};

export default ContractCard;
