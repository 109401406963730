import { useQueryClient } from 'react-query';
import { useApi } from '../api/useApi';
import { useNetworkState } from 'react-use';
import { useContract } from '../contracts/useContract';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFilesystem } from '../useFilesystem';
import {
    formName,
    formNameFile,
    getLocalFormTimestamps,
    getLocalPhotoTimestamps,
    photoVaultName,
    photoVaultNameFile,
    setLocalFormTimestamp,
    setLocalPhotoTimestamp,
} from '../../helper/contract';
import { IVaultFile } from '../../components/BLForms/formInterfaces';
import { SContractPhotoVault, SVaultFile } from '../../components/BLForms/useVault';
import dayjs from 'dayjs';
import {
    IContractPhoto,
    IPhotoVault,
} from '../../../../shared/interfaces/backend/protocol.interface';
import { gql } from 'graphql-request';
import { useUserHasAccess } from '../user/useUserHasAccess';
import { EContractState } from '../../interfaces/contract/contract.model';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../components/Auth/userAtom';

export const useOfflineContractInitialisation = (contractId?: string) => {
    const queryClient = useQueryClient();
    const { postGql } = useApi();
    const { online } = useNetworkState();
    const { data: contract } = useContract(contractId);
    const [response, setResponse] = useState<string | undefined>(undefined);
    const [surveysDone, setSurveysDone] = useState<boolean>(false);

    const { saveFile } = useFilesystem();

    const { id } = useRecoilValue(userAtom);

    const isAdmin = useUserHasAccess('admin');
    const isSv = useUserHasAccess('sv');

    const isInEdit = useMemo(() => {
        if (contract) {
            if (
                contract.state === EContractState.InValidation &&
                isAdmin &&
                contract.validator?.id === id
            ) {
                return true;
            }
            if (
                contract.state === EContractState.Surveying &&
                isSv &&
                contract.surveyor?.id === id
            ) {
                return true;
            }
        }
        return false;
    }, [contract, id, isAdmin, isSv]);

    //callback um alle formulare zu updaten die neuer auf dem server sind
    const setSurveyData = useCallback(async () => {
        if (contract) {
            const localFormTimestamps = await getLocalFormTimestamps();
            const localPhotoTimestamps = await getLocalPhotoTimestamps();

            //wenn der Auftrag Besichtigungen hat
            if (contract.surveys?.length) {
                //alle Besichtigungen durchgehen
                for (const serverSurvey of contract.surveys) {
                    //Server Besichtigung in Objekt parsen
                    const serverFormParsed = JSON.parse(serverSurvey.form) as IVaultFile;

                    const formHash = formName(contract.id, serverSurvey);

                    //Serverstand überprüfen
                    const serverJsonValid = await SVaultFile.isValid(serverFormParsed);

                    if (!serverJsonValid) {
                        await setLocalFormTimestamp(formHash);
                    } else {
                        const localHasNoTimestamp = !localFormTimestamps[formHash];

                        const serverIsNewer = dayjs(serverFormParsed.meta.lastSaved).isAfter(
                            dayjs(localFormTimestamps[formHash])
                        );

                        if (localHasNoTimestamp || serverIsNewer) {
                            //Lokalen Stand mit Serverstand (über-)schreiben
                            await saveFile(
                                {
                                    name: formNameFile(contract.id, serverSurvey),
                                    arrayBuffer: new TextEncoder().encode(
                                        JSON.stringify(serverFormParsed)
                                    ),
                                },
                                ['forms', contract.id]
                            );
                            //Timestamp setzen
                            await setLocalFormTimestamp(formHash, serverFormParsed.meta.lastSaved);
                        } else {
                            await setLocalFormTimestamp(formHash);
                        }
                    }

                    // Photos start here
                    const serverPhotosParsed = JSON.parse(serverSurvey.photo) as IPhotoVault;

                    const photoHash = photoVaultName(contract.id, serverSurvey);

                    //Serverstand überprüfen
                    const serverPhotosValid = await SContractPhotoVault.isValid(serverPhotosParsed);

                    if (!serverPhotosValid) {
                        await setLocalPhotoTimestamp(photoHash);
                    } else {
                        const localPhotosHaveNoTimestamp = !localPhotoTimestamps[photoHash];

                        const serverPhotosAreNewer = dayjs(serverPhotosParsed.lastSaved).isAfter(
                            dayjs(localPhotoTimestamps[photoHash])
                        );

                        if (localPhotosHaveNoTimestamp || serverPhotosAreNewer) {
                            let newTimeStamp =
                                serverPhotosParsed.lastSaved ?? dayjs().toISOString();

                            //Jedes Bild durchgehen und nach und nach ins filesystem laden
                            for (let x = 0; x < serverPhotosParsed.photos.length; x++) {
                                let photo = serverPhotosParsed.photos[x] as IContractPhoto;

                                if (!photo.uploaded || photo.wasDeleted) {
                                    continue;
                                }

                                if (photo.rotated) {
                                    const contentQ = await postGql<{ photo: string }>(
                                        gql`
                                            query ($surveyID: ID!, $slotName: String!) {
                                                photo(surveyID: $surveyID, slotName: $slotName)
                                            }
                                        `,
                                        {
                                            surveyID: serverSurvey.id,
                                            slotName: photo.slotName + '-rotated',
                                        }
                                        // eslint-disable-next-line @typescript-eslint/no-loop-func
                                    ).catch((error: Error) => {
                                        if (error.message === '404') {
                                            photo = {
                                                ...photo,
                                                filePath: '',
                                                timestamp: dayjs().toISOString(),
                                                patches: [],
                                                fileMissing: true,
                                                uploaded: false,
                                                wasDeleted: false,
                                            };
                                            newTimeStamp = photo.timestamp ?? dayjs().toISOString();
                                        }
                                        return { photo: '' };
                                    });

                                    if (contentQ.photo) {
                                        const photoSplit = contentQ.photo.split('base64,');

                                        if (!photo.filePath) {
                                            photo.filePath = `${contract.id}-${serverSurvey.id}-${serverSurvey.name}-${photo.slotName}-rotated.jpg`;
                                            photo.timestamp = dayjs().toISOString();
                                            newTimeStamp = photo.timestamp;
                                        }

                                        await saveFile(
                                            {
                                                name: photo.filePath,
                                                arrayBuffer: Buffer.from(
                                                    photoSplit[1] ?? '',
                                                    'base64'
                                                ),
                                            },
                                            ['photos', contract.id]
                                        );
                                    }
                                }

                                const contentQ = await postGql<{ photo: string }>(
                                    gql`
                                        query ($surveyID: ID!, $slotName: String!) {
                                            photo(surveyID: $surveyID, slotName: $slotName)
                                        }
                                    `,
                                    { surveyID: serverSurvey.id, slotName: photo.slotName }
                                    // eslint-disable-next-line @typescript-eslint/no-loop-func
                                ).catch((error: Error) => {
                                    if (!photo.rotated && error.message === '404') {
                                        photo = {
                                            ...photo,
                                            filePath: '',
                                            timestamp: dayjs().toISOString(),
                                            patches: [],
                                            fileMissing: true,
                                            uploaded: false,
                                            wasDeleted: false,
                                        };
                                        newTimeStamp = photo.timestamp ?? dayjs().toISOString();
                                    }
                                    return { photo: '' };
                                });

                                if (contentQ.photo) {
                                    const photoSplit = contentQ.photo.split('base64,');

                                    if (!photo.rotated && !photo.filePath) {
                                        photo.filePath = `${contract.id}-${serverSurvey.id}-${serverSurvey.name}-${photo.slotName}.jpg`;
                                        photo.timestamp = dayjs().toISOString();
                                        newTimeStamp = photo.timestamp;
                                    }

                                    await saveFile(
                                        {
                                            name: `${contract.id}-${serverSurvey.id}-${serverSurvey.name}-${photo.slotName}.jpg`,
                                            arrayBuffer: Buffer.from(photoSplit[1] ?? '', 'base64'),
                                        },
                                        ['photos', contract.id]
                                    );
                                }

                                serverPhotosParsed.photos[x] = photo;
                            }

                            serverPhotosParsed.lastSaved = newTimeStamp;

                            // Lokalen Stand mit Serverstand (über-)schreiben
                            await saveFile(
                                {
                                    name: photoVaultNameFile(contract.id, serverSurvey),
                                    arrayBuffer: new TextEncoder().encode(
                                        JSON.stringify(serverPhotosParsed)
                                    ),
                                },
                                ['photo-vaults', contract.id]
                            );

                            await setLocalPhotoTimestamp(photoHash, newTimeStamp);
                        } else {
                            await setLocalPhotoTimestamp(photoHash, dayjs().toISOString());
                        }
                    }
                }
            }
        }
    }, [contract, postGql, saveFile]);

    useEffect(() => {
        if (contract) {
            if (contract.surveys?.length && isInEdit) {
                void setSurveyData().then(() => {
                    setSurveysDone(true);
                });
            } else {
                setSurveysDone(true);
            }
        }
    }, [contract, isInEdit, setSurveyData]);

    useEffect(() => {
        if (online && contractId && surveysDone) {
            setSurveysDone(false);
            setResponse(contractId);
        }
    }, [online, contractId, surveysDone, queryClient]);

    return { lastFetchedId: response };
};
