import React, { useEffect, useRef } from 'react';
import useSummaryProgress from '../../hooks/useSummaryProgress';
import { IContract } from '../../interfaces/contract/contract.model';
import { ProgressBar } from '../BLForms/atoms/ProgressBar';
import { useRecoilValue } from 'recoil';
import { contractUploadSelector } from '../../recoil/atoms/contractUploadState';

interface IContractCardProgress {
    contract: IContract;
}

const ContractCardProgress: React.FC<IContractCardProgress> = (props) => {
    const { contract } = props;

    const isCalced = useRef(false);

    const { calc } = useSummaryProgress({ contract: contract });

    const progress = useRecoilValue(contractUploadSelector(contract.id));

    useEffect(() => {
        if (!isCalced.current) {
            isCalced.current = true;
            void calc();
        }
    }, [calc, contract.id]);

    return (
        <>
            <ProgressBar percentage={Math.ceil(progress)} />
        </>
    );
};

export default ContractCardProgress;
