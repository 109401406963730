import { useUser } from './user/useUser';
import { useContractList } from './contracts/useContractList';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNetworkState } from 'react-use';
import { lfStorage, OfflineCache, REACT_QUERY_KEY } from '../helper/offline';
import { contractKeys } from './contracts/queryKeyFactory';
import { useSyncContext } from '../context/syncContext';
import {
    contractIsEditable,
    getLocalFormTimestamps,
    getLocalPhotoTimestamps,
    ILocalTimestamps,
    lfContractMetaStore,
    serverContractSurveyIsNewer,
} from '../helper/contract';
import { IContract } from '../interfaces/contract/contract.model';
import { useOfflineContractInitialisation } from './sync/useOfflineContractInitialisation';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../components/Auth/userAtom';

//hook um alle auftrag relevanten Daten zu ziehen

export const useOfflineDataInitialisation = () => {
    const { online } = useNetworkState();
    const { id: userId } = useRecoilValue(userAtom);
    const [syncContext, setSyncContext] = useSyncContext();

    useUser(true);

    const { data: contracts, isFetched } = useContractList(60000);

    const [contractIdToFetch, setContractIdToFetch] = useState<string | undefined>();

    const readyForNext = useRef<boolean | null>(null);

    const { lastFetchedId } = useOfflineContractInitialisation(contractIdToFetch);

    //alle aktiven Aufträge ziehen
    const [activeContracts, setActiveContracts] = useState<IContract[]>([]);

    //aktuellen array index anhand der aktiven
    const currentIndex = useMemo(() => {
        return activeContracts.findIndex((contract) => contract.id === lastFetchedId) ?? 0;
    }, [activeContracts, lastFetchedId]);

    const syncDone = useCallback(() => {
        if (userId) {
            readyForNext.current = true;

            setTimeout(() => {
                if (syncContext.firstSync) {
                    void lfContractMetaStore
                        .setItem(`firstSync${userId}`, new Date().toUTCString())
                        .then(() => {
                            setSyncContext((current) => {
                                return {
                                    ...current,
                                    syncing: false,
                                    firstSync: false,
                                };
                            });
                        });
                } else {
                    setSyncContext((current) => {
                        return {
                            ...current,
                            syncing: false,
                            firstSync: false,
                        };
                    });
                }
            }, 1000);
        }
    }, [setSyncContext, syncContext.firstSync, userId]);

    // Callback um nächste contractId zu finden
    const setNextContractId = useCallback(async () => {
        if (!userId || readyForNext.current === false) {
            return;
        }

        readyForNext.current = false;

        if (activeContracts.length) {
            //immer den aktiven query stand ziehen
            const cache = await lfStorage.getItem(REACT_QUERY_KEY).then((res) => {
                //hash array erzeugen zur schnelleren abfrage
                return (res as unknown as OfflineCache<IContract>).clientState.queries;
            });

            const cacheHashes = cache.map((single) => single.queryHash);

            const formTimestamps: ILocalTimestamps = await getLocalFormTimestamps();
            const photoTimestamps: ILocalTimestamps = await getLocalPhotoTimestamps();

            let contractId: undefined | string = undefined;

            //alle aktiven aufträge seit dem letzten gesyncten durchgehen
            for (let x = currentIndex; x < activeContracts.length; x++) {
                const listContract = activeContracts[x + 1];

                if (!listContract || !contractIsEditable(listContract)) {
                    continue;
                }

                const contractMissingInCacheCache = !cacheHashes.includes(
                    `["${contractKeys.single(listContract.id).join('","')}"]`
                );

                //Auftrag fehlt generell im Cache
                if (contractMissingInCacheCache) {
                    contractId = listContract.id;
                    break;
                }

                const cacheContract = cache.find(
                    (item) =>
                        item.queryHash === `["${contractKeys.single(listContract.id).join('","')}"]`
                );

                //cache ist alt ab einer Stunde
                const cacheIsStale =
                    cacheContract &&
                    cacheContract.state.dataUpdatedAt < new Date().getTime() - 3600000;

                //Auftrag im cache ist älter als eine Stunde
                if (cacheIsStale) {
                    contractId = listContract.id;
                    break;
                }

                const serverIsNewer = serverContractSurveyIsNewer(
                    listContract,
                    formTimestamps,
                    photoTimestamps
                );

                //Form Zeitstempel auf Server ist neuer als Lokal
                if (serverIsNewer) {
                    contractId = listContract.id;
                    break;
                }
            }

            if (contractId) {
                setContractIdToFetch(contractId);
            } else {
                syncDone();
            }
        } else if (isFetched) {
            syncDone();
        }
    }, [activeContracts, isFetched, currentIndex, syncDone, userId]);

    //setzt die aktuelle sync position
    useEffect(() => {
        setSyncContext((current) => {
            return {
                ...current,
                position: currentIndex,
            };
        });
    }, [currentIndex, setSyncContext]);

    //anzahl aktiver contracts
    useEffect(() => {
        setSyncContext((current) => {
            return {
                ...current,
                count: activeContracts.length,
            };
        });
    }, [activeContracts, setSyncContext]);

    useEffect(() => {
        if (isFetched) {
            const tempActive = contracts?.filter((contract) => contractIsEditable(contract)) ?? [];
            if (tempActive.length) {
                setActiveContracts(tempActive);
            } else {
                syncDone();
            }
        }
    }, [contracts, isFetched, syncDone]);

    //Start
    useEffect(() => {
        if (userId && online) {
            if (readyForNext.current === null) {
                if (!syncContext.syncing) {
                    void lfContractMetaStore.getItem(`firstSync${userId}`).then((item) => {
                        if (item) {
                            setSyncContext((current) => {
                                return {
                                    ...current,
                                    syncing: true,
                                    firstSync: false,
                                };
                            });
                        } else {
                            setSyncContext((current) => {
                                return {
                                    ...current,
                                    syncing: true,
                                    firstSync: true,
                                };
                            });
                        }
                    });
                }
            }
        }
    }, [online, setSyncContext, syncContext.syncing, syncDone, userId]);

    //setzt den ersten contract zum ziehen, löst die untere schleife aus
    useEffect(() => {
        if (online && userId && syncContext.syncing) {
            if (readyForNext.current === null) {
                if (activeContracts.length) {
                    void setNextContractId();
                }
            }
        }
    }, [activeContracts.length, online, setNextContractId, syncContext.syncing, userId]);

    useEffect(() => {
        if (
            lastFetchedId &&
            syncContext.syncing &&
            contractIdToFetch &&
            lastFetchedId === contractIdToFetch
        ) {
            readyForNext.current = true;
            void setNextContractId();
        }
    }, [contractIdToFetch, lastFetchedId, setNextContractId, syncContext.syncing]);
};
