import { atom } from 'recoil';
import { IContract } from 'src/interfaces/contract/contract.model';
import { v4 } from 'uuid';

interface IContractListMapState {
    contract: IContract | null;
    flyTo: IContract | null;
}

export const contractListMapState = atom<IContractListMapState>({
    key: `contract-list-map-state/${v4()}`,
    default: {
        contract: null,
        flyTo: null,
    },
});
