import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { IContract } from '../../interfaces/contract/contract.model';
import { getOpenedContracts } from '../../helper/contract';
import { ContractListColumn } from './ContractListColumn';
import { useContractListFilter } from './hooks/useContractListFilter';
import localforage from 'localforage';
import { frontendContractList, IFrontendContractList } from './recoil/contractList';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userAtom } from '../Auth/userAtom';
import { QueryInfo } from './QueryInfo';
import { useOfflineDataInitialisation } from '../../hooks/useOfflineDataInitialisation';
import { useWindowResize } from '../../hooks/useWindowDimensions';
import { ContractsToUploadBar } from './ContractsToUploadBar';

type TContractListName = 'neu' | 'angebote' | 'aktive';

export interface ISteps {
    id: '1' | '2' | '3';
    name: TContractListName;
    href: '#';
}

export const steps: ISteps[] = [
    { id: '1', name: 'neu', href: '#' },
    { id: '2', name: 'angebote', href: '#' },
    { id: '3', name: 'aktive', href: '#' },
];

export interface IContractsFiltered {
    neu: IContract[];
    angebote: IContract[];
    aktive: IContract[];
}

export interface IContractsWithData {
    lists: IContractsFiltered;
    all: IContract[];
    pinned: {
        neu: string[];
        angebote: string[];
        aktive: string[];
    };
    unPinned: {
        neu: string[];
        angebote: string[];
        aktive: string[];
    };
}

export const lfContractFilterStore = localforage.createInstance({
    version: 4,
    name: 'contractFilter',
});

const ContractList: FunctionComponent = () => {
    const { id: userId } = useRecoilValue(userAtom);

    useOfflineDataInitialisation();

    const [openedContracts, setOpenedContracts] = useState<string[]>();

    const { contractsFiltered } = useContractListFilter(Number(userId));

    const [contractFilter, setContractFilter] = useRecoilState(frontendContractList);

    const [contractStateTab, setContractStateTab] = useState<'neu' | 'angebote' | 'aktive'>(
        typeof window !== 'undefined'
            ? (window.localStorage.getItem('contractListTab') as TContractListName) ?? 'neu'
            : 'neu'
    );

    const fetchOpenedContracts = useCallback(() => {
        void getOpenedContracts().then((opened) => setOpenedContracts(opened));
    }, []);

    useEffect(() => {
        fetchOpenedContracts();
    }, [fetchOpenedContracts]);

    const { isDesktop } = useWindowResize();

    useEffect(() => {
        void lfContractFilterStore.setItem('filter', contractFilter);
    }, [contractFilter]);

    useEffect(() => {
        void lfContractFilterStore
            .getItem('filter')
            .then((filter) => filter && setContractFilter(filter as IFrontendContractList));
    }, [setContractFilter]);

    return (
        <>
            <div id="contract-list" className="relative flex h-full w-full flex-col gap-2">
                {/* TODO Wurde rausgenommen, da manche SVs anscheineind den Flag noch gesetzt haben und somit die Karte zwar da ist aber nicht ausschaltbar wurde */}
                {/* Map */}
                {/*{contractList.showMap && (*/}
                {/*    <div className="fixed top-10 flex h-full h-screen w-full flex-col">*/}
                {/*        <ContractListMapWrapper standalone={false} />*/}
                {/*    </div>*/}
                {/*)}*/}

                {/* Default Contract List */}
                {/* Message "Es gibt noch  */}
                <div>
                    <ContractsToUploadBar />
                </div>

                {/* Query Info */}
                <div className="relative flex w-full flex-row gap-1 px-2 py-1">
                    <QueryInfo />
                </div>

                <div id="tab-container" className="relative flex w-full flex-row">
                    {!isDesktop && (
                        // Tab NAVIGATION
                        <nav className="h-min w-full ">
                            <div className="relative flex w-full items-center justify-center">
                                <nav className="relative mx-auto flex w-max items-center justify-center overflow-hidden rounded bg-white">
                                    {steps.map((step, stepIdx) => (
                                        <button
                                            key={`svContractListTabKey${step.name}`}
                                            onClick={() => {
                                                localStorage.setItem('contractListTab', step.name);
                                                setContractStateTab(step.name);
                                            }}
                                            className={`relative flex h-12 items-center justify-center px-2 xs:px-8 ${
                                                stepIdx < steps.length - 1 ? 'border-r-[1px]' : ''
                                            }`}
                                            data-test-id={`svContractListTab-${step.name}`}
                                        >
                                            <span
                                                className={`relative flex flex-row items-center gap-1 text-xs uppercase tracking-wider xs:text-sm  ${
                                                    step.name === contractStateTab
                                                        ? 'text-primary'
                                                        : 'text-neutral-400'
                                                }`}
                                            >
                                                {/* Count Bubble */}
                                                <span
                                                    className={`relative mr-1 flex h-6 items-center justify-center rounded-full px-2 text-sm text-white xs:mr-2 ${
                                                        step.name === contractStateTab
                                                            ? 'bg-primary'
                                                            : 'bg-neutral-500'
                                                    }`}
                                                >
                                                    {contractsFiltered.lists[step.name].length}
                                                </span>

                                                <span className="text-sm">{step.name}</span>
                                            </span>

                                            {step.name === contractStateTab && (
                                                <div className="absolute bottom-0 flex h-[2px] w-full bg-primary"></div>
                                            )}
                                        </button>
                                    ))}
                                </nav>
                            </div>
                        </nav>
                    )}
                </div>
                <div id="contracts" className="relative min-h-[300px] flex-grow px-0 lg:px-3">
                    {isDesktop && (
                        <div id="column-grid" className="relative grid grid-cols-3 gap-1">
                            {openedContracts &&
                                steps.map((step) => {
                                    return (
                                        <div
                                            key={`contractListColumn${step.id}`}
                                            className="relative flex w-full flex-col"
                                        >
                                            {/* Column Headline */}
                                            <div className="relative flex w-full flex-col items-center justify-center bg-neutral-400 py-3">
                                                <h4 className="font-serif capitalize tracking-wide text-white">
                                                    {step.name}{' '}
                                                    {
                                                        <>
                                                            (
                                                            {
                                                                contractsFiltered.lists[step.name]
                                                                    .length
                                                            }
                                                            )
                                                        </>
                                                    }
                                                </h4>
                                            </div>
                                            <ContractListColumn
                                                contractsFiltered={contractsFiltered}
                                                stepName={step.name}
                                                userId={Number(userId ?? 0)}
                                                openedContracts={openedContracts}
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    )}
                    {!isDesktop && openedContracts && (
                        <>
                            <div className="relative flex flex-row px-0 lg:px-2">
                                <ContractListColumn
                                    contractsFiltered={contractsFiltered}
                                    stepName={contractStateTab}
                                    userId={Number(userId ?? 0)}
                                    openedContracts={openedContracts}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default ContractList;
