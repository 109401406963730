import { useCallback, useMemo } from 'react';
import { EContractState, IContract } from '../../../interfaces/contract/contract.model';
import { contractIsEditable } from '../../../helper/contract';
import { useContractList } from '../../../hooks/contracts/useContractList';
import { EContractType } from '../../../../../shared/interfaces/backend/contract.interface';
import { useRecoilValue } from 'recoil';
import { frontendContractList } from '../recoil/contractList';
import { IContractsFiltered, IContractsWithData, steps } from '../ContractList';
import dayjs from 'dayjs';
import Fuse from 'fuse.js';

import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isToday);

export const useContractListFilter = (userId: number | undefined) => {
    const { data: contracts } = useContractList();
    const contractList = useRecoilValue(frontendContractList);

    const isContractPinned = useCallback(
        (favoured: string[]) => {
            if (!userId) {
                return false;
            }

            return favoured.includes(String(userId));
        },
        [userId]
    );

    const filteredContractsByState = useMemo(() => {
        let contractsToFilter: IContract[] = [...(contracts ?? [])];

        // Filter only Open states https://webbar.jetbrains.space/p/immobilienbesucher/issue-boards/FE--QA--Sprint?issues=IMMOBILIENBESUCHER-T-137
        if (contractList.showOnlyOpenDates && contracts) {
            contractsToFilter = contracts?.filter((contract: IContract) => {
                return !contract.surveyDate;
            });
        }

        if (contractList.filterPinned && contracts) {
            contractsToFilter = contractsToFilter.filter((contract) => {
                if (contract.favoured === undefined) return false;
                return isContractPinned(contract.favoured);
            });
        }

        if (contractList.filterToday && contracts) {
            contractsToFilter = contractsToFilter.filter((contract) => {
                return dayjs(contract.surveyDate).isToday();
            });
        }

        const contractsNeu: IContract[] =
            contractsToFilter?.filter(
                (el) =>
                    el.state === EContractState.Submitted &&
                    (!el.offers || !el.offers.some((offer) => offer.surveyor.id === String(userId)))
            ) ?? [];
        const contractsAngebote: IContract[] =
            contractsToFilter?.filter(
                (el) =>
                    el.state === EContractState.Submitted &&
                    el.offers &&
                    el.offers.some((offer) => offer.surveyor.id === String(userId))
            ) ?? [];
        const contractsAktive: IContract[] =
            contractsToFilter?.filter((el) => contractIsEditable(el)) ?? [];

        return { neu: contractsNeu, angebote: contractsAngebote, aktive: contractsAktive };
    }, [
        contractList.filterPinned,
        contractList.filterToday,
        contractList.showOnlyOpenDates,
        contracts,
        isContractPinned,
        userId,
    ]);

    const filteredContractsByType = useMemo(() => {
        if (!contractList.filterAussen && !contractList.filterInnen) {
            return { neu: [], aktive: [], angebote: [] };
        }

        if (contractList.filterAussen && contractList.filterInnen) {
            return filteredContractsByState;
        }

        const newContracts = { ...filteredContractsByState };

        Object.entries(filteredContractsByState).map((contractsByState: [string, IContract[]]) => {
            newContracts[contractsByState[0]] = contractsByState[1].filter((el) =>
                contractList.filterAussen
                    ? el.contractType === EContractType.Outer
                    : el.contractType === EContractType.Inner
            );
        });

        return newContracts;
    }, [contractList.filterAussen, contractList.filterInnen, filteredContractsByState]);

    const contractsFiltered = useMemo((): IContractsWithData => {
        let newContracts: IContractsFiltered = { ...filteredContractsByType };

        if (contractList.queryType === 'address') {
            // Address Filter
            if (contractList.addressQuery) {
                Object.entries(filteredContractsByType).map(
                    (contractsByState: [string, IContract[]]) => {
                        newContracts[contractsByState[0]] = contractsByState[1].filter(
                            (el) => el.id === contractList.addressQuery
                        );
                    }
                );
            }
        } else {
            // Default Fulltext
            if (contractList.query.trim().length !== 0) {
                const fuzzedContracts = {
                    neu: [] as IContract[],
                    aktive: [] as IContract[],
                    angebote: [] as IContract[],
                };

                Object.entries(filteredContractsByType).forEach((value) => {
                    // Run fuse per neu/aktive/angebote and fill back to fuzzedContrats
                    const fuse = new Fuse(value[1], {
                        keys: [
                            'address.city',
                            'address.street',
                            'address.postalCode',
                            'company.name',
                            'contact1.firstName',
                            'contact1.lastName',
                            'contact1.phone',
                            'contact1.mobile',
                            'contact1.email',
                            'contact2.firstName',
                            'contact2.lastName',
                            'contact2.email',
                            'contact2.phone',
                            'contact2.mobile',
                            'fileNumber',
                            'id',
                        ],
                        shouldSort: true,
                        includeScore: true,
                        threshold: 0.3,
                        distance: 50,
                        findAllMatches: false,
                        minMatchCharLength: 1,
                    });

                    const fuzzed = fuse.search(contractList.query.trim());

                    const filtered: IContract[] = [];
                    fuzzed.forEach((item) => filtered.push(item.item));

                    fuzzedContracts[value[0]] = filtered;
                });

                // Obsolete
                // Object.entries(filteredContractsByType).map(
                //     (contractsByState: [string, IContract[]]) => {
                //         return (newContracts[contractsByState[0]] = contractsByState[1].filter(
                //             (el) => {
                //                 // console.log(el.contact1?.mobile);
                //                 return (
                //                     el.address?.city
                //                         ?.toLowerCase()
                //                         .includes(contractList.query.trim().toLowerCase()) ||
                //                     el.address?.street
                //                         ?.toLowerCase()
                //                         .includes(contractList.query.trim().toLowerCase()) ||
                //                     el.address?.postalCode
                //                         ?.toLowerCase()
                //                         .includes(contractList.query.trim().toLowerCase()) ||
                //                     el.company?.name
                //                         ?.toLowerCase()
                //                         .includes(contractList.query.trim().toLowerCase()) ||
                //                     el.fileNumber
                //                         ?.toLowerCase()
                //                         .includes(contractList.query.trim().toLowerCase()) ||
                //                     getContractNumber(el.id)
                //                         .toLowerCase()
                //                         .includes(contractList.query.trim().toLowerCase())
                //                 );
                //             }
                //         ));
                //     }
                // );

                newContracts = { ...fuzzedContracts };
            }
        }

        const result: IContractsWithData = {
            lists: newContracts,
            all: [...newContracts.neu, ...newContracts.angebote, ...newContracts.aktive],
            pinned: {
                neu: [],
                aktive: [],
                angebote: [],
            },
            unPinned: {
                neu: [],
                aktive: [],
                angebote: [],
            },
        };

        // inCorrection -> express -> psotalCode Express shall always be in the beginning of each list
        Object.entries(result.lists).map((contractsByState: [string, IContract[]]) => {
            newContracts[contractsByState[0]] = contractsByState[1].sort((a, b) => {
                // Sort by 'inCorrection' field (true comes before false)
                if (a.inCorrection && !b.inCorrection) {
                    return -1;
                }
                if (!a.inCorrection && b.inCorrection) {
                    return 1;
                }

                // Sort by 'express' field (true comes before false)
                if (a.express && !b.express) {
                    return -1;
                }
                if (!a.express && b.express) {
                    return 1;
                }

                // Sort by 'address.postalCode' field
                return a.address?.postalCode?.localeCompare(b.address?.postalCode ?? '') ?? -1;
            });
        });

        // Count pinned/unpinned
        steps.forEach((step) => {
            const itemsPinned = newContracts?.[step.name]
                .filter((item) => {
                    return isContractPinned(item.favoured ?? []);
                })
                .map((contract) => contract.id);
            const itemsUnPinned = newContracts?.[step.name]
                .filter((item) => {
                    return !isContractPinned(item.favoured ?? []);
                })
                .map((contract) => contract.id);

            result.pinned[step.name] = itemsPinned;
            result.unPinned[step.name] = itemsUnPinned;
        });

        return result;
    }, [
        filteredContractsByType,
        contractList.queryType,
        contractList.addressQuery,
        contractList.query,
        isContractPinned,
    ]);

    return {
        filteredContractsByState,
        filteredContractsByType,
        contractsFiltered,
        contracts,
        isContractPinned,
    };
};
