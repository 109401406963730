import { IContract } from '../../../interfaces/contract/contract.model';
import { useMemo } from 'react';
import dayjs from 'dayjs';

export const useContractHelper = (contract: IContract) => {
    const amountWEs = useMemo(() => {
        if (!contract.surveys) return 0;

        const filtered = contract.surveys?.filter((item) => {
            return item.type === 'Innenbesichtigung';
        });

        return filtered.length ?? 0;
    }, [contract.surveys]);

    const isOverdue = useMemo((): boolean => {
        return dayjs().isAfter(contract.deadlineSV, 'days');
    }, [contract]);

    return { amountWEs, isOverdue };
};
