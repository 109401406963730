import { EContractType } from '../../../../../shared/interfaces/backend/contract.interface';
import { useCallback } from 'react';
import { faHandshake, faHouse, faPersonDigging } from '@fortawesome/free-solid-svg-icons';

export const useContractTypeIcon = () => {
    const getIcon = useCallback((contractType: EContractType | undefined) => {
        switch (contractType) {
            case EContractType.Outer:
                return faHouse;
            case EContractType.Inner:
                return faHandshake;
            case EContractType.Bautenstandsbericht:
                return faPersonDigging;
            default:
                return faHouse;
        }
    }, []);

    return { getIcon };
};
