import { IContract } from '../interfaces/contract/contract.model';

export const useContractTypeLabel = (contractType: IContract['contractType']) => {
    switch (contractType) {
        case 'Aussenbesichtigung':
        case 'Außenbesichtigung':
            return 'Außenbesichtigung';
        case 'Innenbesichtigung':
            return 'Außen- und Innenbesichtigung';
    }

    return contractType;
};
