import { useQuery } from 'react-query';
import { gql } from 'graphql-request';
import { useApi } from '../api/useApi';
import { contractKeys } from './queryKeyFactory';
import { EContractState, IContract } from '../../interfaces/contract/contract.model';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../components/Auth/userAtom';

export const useContractList = (staleTime = 10000) => {
    const { id: userId } = useRecoilValue(userAtom);
    const { postGql } = useApi();

    const fetchAllContracts = async (
        contractsSoFar: IContract[] = [],
        cursor: number | null = null,
        limit = 500
    ): Promise<IContract[]> => {
        const { contracts } = await postGql<{ contracts: IContract[] }>(
            gql`
                query ($input: ContractsInput!) {
                    contracts(input: $input) {
                        id
                        fileNumber
                        accessible
                        contractType
                        objectType
                        state
                        saved
                        fee
                        inCorrection
                        deadlineSV
                        deadlineEK
                        surveyDate
                        favoured
                        contact1 {
                            firstName
                            lastName
                            phone
                            mail
                        }
                        contact2 {
                            firstName
                            lastName
                            phone
                            mail
                        }
                        formType
                        express
                        company {
                            name
                            nameShort
                        }
                        surveyor {
                            id
                            fields {
                                absenceTill
                                absenceFrom
                            }
                        }
                        address {
                            postalCode
                            city
                            country
                            houseNo
                            street
                        }
                        position {
                            corrected
                            longitude
                            latitude
                        }
                        offers {
                            surveyor {
                                id
                            }
                        }
                        surveys {
                            id
                            name
                            saved
                            deleted
                        }
                    }
                }
            `,
            {
                input: {
                    archived: false,
                    take: limit,
                    cursor,
                },
            },
            `queryContracts${cursor ?? ''}`
        );

        const filteredContracts = contracts.filter((contract) => contract.accessible);

        if (contracts.length === limit) {
            const nextCursor = Number(contracts[contracts.length - 1]?.id) ?? 0;
            return fetchAllContracts([...contractsSoFar, ...filteredContracts], nextCursor, limit);
        }
        return [...contractsSoFar, ...filteredContracts];
    };

    return useQuery(
        contractKeys.list,
        async () => {
            return (await fetchAllContracts()).filter((contract) => {
                if (contract.state === EContractState.Surveying) {
                    if (!contract.surveyor || contract.surveyor.id !== userId) {
                        return false;
                    }
                }
                return true;
            });
        },
        {
            enabled: !!userId,
            staleTime,
        }
    );
};
