import React from 'react';
import { ButtonRound } from '../atoms/Buttons/ButtonRound';
import { faMapLocation } from '@fortawesome/pro-duotone-svg-icons';
import { useRecoilState } from 'recoil';
import { contractListMapState } from './recoil/contractListMapState';
import { IContract } from '../../interfaces/contract/contract.model';
import { useRouter } from 'next/router';

export interface IContractMapPushButton {
    contract: IContract;
}

export const ContractMapPushButton: React.FC<IContractMapPushButton> = (props) => {
    const { contract } = props;

    const [mapState, setMapState] = useRecoilState(contractListMapState);

    const { push } = useRouter();

    return (
        <>
            <ButtonRound
                icon={faMapLocation}
                clean={true}
                onClick={() => {
                    // Set the location for /map component and lead the user to the map
                    setTimeout(() => {
                        setMapState({
                            ...mapState,
                            contract: contract,
                            flyTo: contract,
                        });

                        void push('/map');
                    }, 100);
                }}
            />
        </>
    );
};
