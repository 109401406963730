import { atom, selector } from 'recoil';
import { v4 } from 'uuid';

export const contractUploadState = atom<{
    [contractId: string]: number;
}>({
    key: `contract-upload-state/${v4()}`,
    default: {},
});

export const contractUploadSelector = (contractId: string) => {
    return selector<number>({
        key: `contract-upload-state-${contractId}/${v4()}`,
        get: ({ get }) => get(contractUploadState)[contractId] ?? 0,
    });
};
