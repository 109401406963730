import React from 'react';
import { IContract } from '../../interfaces/contract/contract.model';
import { Modal } from '../atoms/Modal/Modal';

interface IContractsToUploadModalProps {
    show: boolean;
    onClose: () => void;
    contracts: IContract[];
}

export const ContractsToUploadModal: React.FC<IContractsToUploadModalProps> = ({
    show,
    onClose,
    contracts,
}) => {
    return (
        <Modal show={show} closeable={true} afterClose={onClose}>
            <div>
                <h1 className="text-lg font-medium text-primary">Überfällige Aufträge</h1>
                <h2 className="relative mb-4 w-10/12 text-xs text-neutral-600">
                    Folgende Aufträge wurden noch nicht übertragen. Bitte laden Sie ihre Daten hoch
                    und schließen diese ab:
                </h2>
                <ul className="relative flex max-h-[calc(100vh-200px)] w-full flex-col gap-1">
                    {contracts.map((contract) => (
                        <li
                            key={contract.id}
                            className="flex cursor-pointer items-center justify-between px-4 py-3 hover:bg-gray-50"
                        >
                            <a
                                href={`/contract/?id=${contract.id}`}
                                className="font-medium text-primary"
                            >
                                {contract.fileNumber} - {contract.address?.street}{' '}
                                {contract.address?.postalCode} {contract.address?.city}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </Modal>
    );
};
