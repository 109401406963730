import { useFilesystem } from '../../../hooks/useFilesystem';
import { useCallback } from 'react';
import { SVaultFile } from '../useVault';
import { IVaultFile } from '../formInterfaces';
import { formNameFile } from '../../../helper/contract';
import { ISurvey } from '../../../interfaces/contract/survey.model';

export const useLoadVault = () => {
    const { readFile } = useFilesystem();

    const loadVault = useCallback(
        async (options: { contractId: string; survey: ISurvey }): Promise<IVaultFile | null> => {
            const fileName = formNameFile(options.contractId, options.survey);

            const path = ['forms', options.contractId];

            const result = await readFile(fileName, path, { response: 'plain' }).catch(() => {});

            // eslint-disable-next-line no-console
            // console.log('loadVAULT:', fileName, path, result);

            if (typeof result?.data === 'string' && result.data !== '') {
                const vaultFile = JSON.parse(result?.data ?? {}) as IVaultFile;

                if (await SVaultFile.isValid(vaultFile)) {
                    return vaultFile;
                }
            }

            return null;
        },
        [readFile]
    );

    return { loadVault };
};
