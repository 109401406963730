// hooks/useHasAccess.ts
import { useMemo } from 'react';
import { useUserRoles } from './useUserRoles';

export const useUserHasAccess = (access: string): boolean => {
    const roles = useUserRoles();

    return useMemo(() => {
        if (!roles.isSuccess || !roles.data) {
            return false;
        }

        return roles.data.some((role) => role.access.includes(access));
    }, [roles, access]);
};
