import { useMemo } from 'react';
import { contractIsEditable } from '../../../helper/contract';
import dayjs from 'dayjs';
import { useContractList } from '../../../hooks/contracts/useContractList';

export const useContractsToUpload = () => {
    const { data: contracts } = useContractList();

    return useMemo(() => {
        if (!contracts) {
            return [];
        }

        return contracts.filter(
            (contract) =>
                contractIsEditable(contract) &&
                dayjs().isAfter(dayjs(contract.deadlineSV).add(1, 'day'))
        );
    }, [contracts]);
};
